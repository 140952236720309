
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import { UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { delFiles, addArticle, getArticleIdCon, updateArticle } from "@/api"
import { post } from "@/utils/request"
import { useRoute } from "vue-router";
import WangEditor from 'wangeditor';

export default defineComponent({
  components:{
    UploadOutlined
  },
  props: {
    sign: Number
  },
  setup(props) {
    const route = useRoute();
    const pageTitle = ref("发布");
    const selectVal = ref("随笔");
    const title = ref("");
    const source = ref("原创");
    const cover = ref("");
    const author = ref("");
    const info = ref("");
    const content = ref();
    let instance: any;
    const complete = ref(0);
    const editor = ref();
    // interface FileListType {
    //   uid: number,
    //   name: string,
    //   status: string,
    //   url: string,
    //   thumbUrl: string,
    // }
    const loading = ref(false)
    const fileList = ref<any>([
      
    ]);
    const context = ref("123456");
    const uploadImg = (option: any) => {
      const file = option.file
      const params = new FormData()
      params.append('multipartFile', file)
      const uId = file.uid.split("-")[2] + file.uid.split("-")[3]
      params.append('fileUid', uId)
      params.append('fileSize', option.file.size)
      params.append('fileName', option.file.name.split(".")[0])
      params.append('sign', '1')
      if (option.file.type != "image/jpeg" && option.file.type != "image/png" && option.file.type != "image/webp") {
        return message.error("文件类型只能为jpg/png/webp格式")
      }
      if (option.file.size / 1024 / 1024 > 2) {
        return message.error("文件大小不能超过2MB")
      }
      post("/files/uploadFiles", params, {
        onUploadProgress: (progressEvent: any) => {
          complete.value = (progressEvent.loaded / progressEvent.total * 100 | 0)
          option.onProgress({ percent: complete.value });
        }
      }).then((res: any) => {
        if (res.code == 500) {
          return message.error("上传失败！");
        }
        option.onSuccess(res, option.file);
        message.success("上传成功!");
        cover.value = res.data.url;
      })
    }
    
    const rmFile = (file: any) => {
      const filetype = file.name.split(".")[1];
      const uId = file.uid.split("-")[2] + file.uid.split("-")[3]
      delFiles({file: uId + '.' + filetype, sign: 1}).then((res: any) => {
        if (res.code == 200) {
          message.success("删除成功！");
          cover.value = "";
        }
      })
    }

    // 文章
    const artSuccess = () => {
      title.value = "";
      info.value = "";
      content.value = "";
      author.value = "";
      cover.value = "";
      loading.value = false;
      fileList.value = "";
    }
    const addArt = () => {
      if (title.value == "") {
        return message.warning("请输入标题");
      }
      if (author.value == "") {
        return message.warning("请输入作者");
      }
      if (cover.value == "") {
        return message.warning("请输入封面地址");
      }
      if (info.value == "") {
        return message.warning("请输入简介");
      }
      if (instance.txt.html() == "") {
        return message.warning("请输入内容");
      }
      const params = {
        id: "",
        title: title.value,
        label: selectVal.value,
        info: info.value,
        content: instance.txt.html().replace(/<xmp>/g, ""),
        source: source.value,
        author: author.value,
        cover: cover.value
      }
      loading.value = true;
      if (props.sign == 1) {
        params.id = route.query.id as string;
        updateArticle(params).then((res: any) => {
          if (res.code == 200) {
            loading.value = false;
            return message.success("修改成功");
          }
          loading.value = false;
          message.error("修改失败");
        })
        return;
      }
      addArticle(params).then((res: any) => {
        if (res.code == 200) {
          artSuccess();
          loading.value = false;
          instance.txt.html("");
          return message.success("发布成功");
        }
        loading.value = false;
        message.error("发布失败");
      })
    }

    // 富文本编辑器
    const createEditor = () => {
      instance = new WangEditor(editor.value)
      // Object.assign(instance.config, {
      //     onchange() {
      //         console.log('change');
      //     },
      // })
      instance.config.uploadImgMaxSize = 2 * 1024 * 1024
      instance.config.uploadImgMaxLength = 1
      instance.config.customUploadImg = (files: any, insert: any) => {
      const file = files[0];
      console.log(file)
      const uId = file.lastModified
      const params = new FormData()
      params.append('multipartFile', files[0])
      params.append('fileUid', uId)
      params.append('fileSize', file.size)
      params.append('fileName', file.name.split(".")[0])
      params.append('sign', '1')
      post("/files/uploadFiles", params).then((res: any) => {
        if (res.code == 500) {
          return message.error("上传失败！");
        }
        message.success("上传成功!");
        insert(res.data.url);
      })
        console.log(files)
      }
      

      instance.create()
    }

    onMounted(() => {
      createEditor();
      if (props.sign == 1) {
        const id = route.query.id as string;
        pageTitle.value = "修改"
        if (!id) {
          return;
        }
        getArticleIdCon({id}).then((res: any) => {
          title.value = res.data.data.title;
          info.value = res.data.data.info;
          instance.txt.html(res.data.data.content);
          author.value = res.data.data.author;
          cover.value = res.data.data.cover;
          source.value = res.data.data.source;
          selectVal.value = res.data.data.label;
        })
      }
    })
    onBeforeUnmount(() => {
        instance.destroy();
        instance = null;
    });

    return {
      editor,
      loading,
      pageTitle,
      selectVal,
      title,
      source,
      cover,
      author,
      info,
      content,
      fileList,
      context,
      uploadImg,
      rmFile,
      addArt
    }
  }
})
