<template>
  <div id="article-admin">
    <div class="title-wrapper">
      <div class="title">{{ pageTitle }}创作</div>
    </div>
    <div class="main">
      <ul>
        <li>
          <div class="title">栏目：</div>
          <div class="detail">
            <a-select
              v-model:value="selectVal"
              style="width: 280px"
              @focus="focus"
              ref="select"
              @change="handleChange"
            >
              <a-select-option value="随笔">
                随笔
              </a-select-option>
              <a-select-option value="技术">
                技术
              </a-select-option>
              <a-select-option value="资源">
                资源
              </a-select-option>
            </a-select>
          </div>
        </li>
        <li>
          <div class="title">标题：</div>
          <div class="detail">
            <a-input style="width: 280px;" v-model:value="title" placeholder="请输入标题" />
          </div>
        </li>
        <li>
          <div class="title">来源：</div>
          <div class="detail">
            <a-select
              v-model:value="source"
              style="width: 280px"
              @focus="focus"
              ref="select"
              @change="handleChange"
            >
              <a-select-option value="原创">
                原创
              </a-select-option>
              <a-select-option value="转载">
                转载
              </a-select-option>
            </a-select>
          </div>
        </li>
        <li>
          <div class="title">作者：</div>
          <div class="detail">
            <a-input style="width: 280px;" v-model:value="author" placeholder="作者" />
          </div>
        </li>
        <li>
          <div class="title">封面：</div>
          <div class="detail">
            <a-input style="width: 280px;margin-right: 20px;" v-model:value="cover" placeholder="封面地址" />
            <a-upload
              list-type="picture"
              v-model:fileList="fileList"
              :customRequest="uploadImg"
              :remove="rmFile"
            >
              <a-button> <upload-outlined /> 上传图片 </a-button>
            </a-upload> 
          </div>
        </li>
        <li>
          <div class="title">简介：</div>
          <div class="detail">
            <a-textarea v-model:value="info" placeholder="请输入简介" :rows="4" />
          </div>
        </li>
        <li>
          <div class="title">内容：</div>
          <div class="detail content">
            <div ref='editor'></div>
          </div>
        </li>
        <li>
          <div class="title">操作：</div>
          <div class="detail">
            <a-button :loading="loading" @click="addArt" class="publish">{{ pageTitle }}文章</a-button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import { UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { delFiles, addArticle, getArticleIdCon, updateArticle } from "@/api"
import { post } from "@/utils/request"
import { useRoute } from "vue-router";
import WangEditor from 'wangeditor';

export default defineComponent({
  components:{
    UploadOutlined
  },
  props: {
    sign: Number
  },
  setup(props) {
    const route = useRoute();
    const pageTitle = ref("发布");
    const selectVal = ref("随笔");
    const title = ref("");
    const source = ref("原创");
    const cover = ref("");
    const author = ref("");
    const info = ref("");
    const content = ref();
    let instance: any;
    const complete = ref(0);
    const editor = ref();
    // interface FileListType {
    //   uid: number,
    //   name: string,
    //   status: string,
    //   url: string,
    //   thumbUrl: string,
    // }
    const loading = ref(false)
    const fileList = ref<any>([
      
    ]);
    const context = ref("123456");
    const uploadImg = (option: any) => {
      const file = option.file
      const params = new FormData()
      params.append('multipartFile', file)
      const uId = file.uid.split("-")[2] + file.uid.split("-")[3]
      params.append('fileUid', uId)
      params.append('fileSize', option.file.size)
      params.append('fileName', option.file.name.split(".")[0])
      params.append('sign', '1')
      if (option.file.type != "image/jpeg" && option.file.type != "image/png" && option.file.type != "image/webp") {
        return message.error("文件类型只能为jpg/png/webp格式")
      }
      if (option.file.size / 1024 / 1024 > 2) {
        return message.error("文件大小不能超过2MB")
      }
      post("/files/uploadFiles", params, {
        onUploadProgress: (progressEvent: any) => {
          complete.value = (progressEvent.loaded / progressEvent.total * 100 | 0)
          option.onProgress({ percent: complete.value });
        }
      }).then((res: any) => {
        if (res.code == 500) {
          return message.error("上传失败！");
        }
        option.onSuccess(res, option.file);
        message.success("上传成功!");
        cover.value = res.data.url;
      })
    }
    
    const rmFile = (file: any) => {
      const filetype = file.name.split(".")[1];
      const uId = file.uid.split("-")[2] + file.uid.split("-")[3]
      delFiles({file: uId + '.' + filetype, sign: 1}).then((res: any) => {
        if (res.code == 200) {
          message.success("删除成功！");
          cover.value = "";
        }
      })
    }

    // 文章
    const artSuccess = () => {
      title.value = "";
      info.value = "";
      content.value = "";
      author.value = "";
      cover.value = "";
      loading.value = false;
      fileList.value = "";
    }
    const addArt = () => {
      if (title.value == "") {
        return message.warning("请输入标题");
      }
      if (author.value == "") {
        return message.warning("请输入作者");
      }
      if (cover.value == "") {
        return message.warning("请输入封面地址");
      }
      if (info.value == "") {
        return message.warning("请输入简介");
      }
      if (instance.txt.html() == "") {
        return message.warning("请输入内容");
      }
      const params = {
        id: "",
        title: title.value,
        label: selectVal.value,
        info: info.value,
        content: instance.txt.html().replace(/<xmp>/g, ""),
        source: source.value,
        author: author.value,
        cover: cover.value
      }
      loading.value = true;
      if (props.sign == 1) {
        params.id = route.query.id as string;
        updateArticle(params).then((res: any) => {
          if (res.code == 200) {
            loading.value = false;
            return message.success("修改成功");
          }
          loading.value = false;
          message.error("修改失败");
        })
        return;
      }
      addArticle(params).then((res: any) => {
        if (res.code == 200) {
          artSuccess();
          loading.value = false;
          instance.txt.html("");
          return message.success("发布成功");
        }
        loading.value = false;
        message.error("发布失败");
      })
    }

    // 富文本编辑器
    const createEditor = () => {
      instance = new WangEditor(editor.value)
      // Object.assign(instance.config, {
      //     onchange() {
      //         console.log('change');
      //     },
      // })
      instance.config.uploadImgMaxSize = 2 * 1024 * 1024
      instance.config.uploadImgMaxLength = 1
      instance.config.customUploadImg = (files: any, insert: any) => {
      const file = files[0];
      console.log(file)
      const uId = file.lastModified
      const params = new FormData()
      params.append('multipartFile', files[0])
      params.append('fileUid', uId)
      params.append('fileSize', file.size)
      params.append('fileName', file.name.split(".")[0])
      params.append('sign', '1')
      post("/files/uploadFiles", params).then((res: any) => {
        if (res.code == 500) {
          return message.error("上传失败！");
        }
        message.success("上传成功!");
        insert(res.data.url);
      })
        console.log(files)
      }
      

      instance.create()
    }

    onMounted(() => {
      createEditor();
      if (props.sign == 1) {
        const id = route.query.id as string;
        pageTitle.value = "修改"
        if (!id) {
          return;
        }
        getArticleIdCon({id}).then((res: any) => {
          title.value = res.data.data.title;
          info.value = res.data.data.info;
          instance.txt.html(res.data.data.content);
          author.value = res.data.data.author;
          cover.value = res.data.data.cover;
          source.value = res.data.data.source;
          selectVal.value = res.data.data.label;
        })
      }
    })
    onBeforeUnmount(() => {
        instance.destroy();
        instance = null;
    });

    return {
      editor,
      loading,
      pageTitle,
      selectVal,
      title,
      source,
      cover,
      author,
      info,
      content,
      fileList,
      context,
      uploadImg,
      rmFile,
      addArt
    }
  }
})
</script>

<style lang="scss" scoped>
  #article-admin {

    .main {

      ul {

        li {
          border-bottom: 1px solid #eee;
          padding: 20px;
          display: flex;
          align-items: center;

          .title {
            width: 120px;
            font-weight: 600;
          }
          
          .detail {
            
            ::v-deep(textarea) {
              width: 410px;
            }
          }

          .content {
            width: 90%;
            
            ::v-deep(textarea) {
              width: 100%;
            }
          }
        }

        .publish {
          display: block;
          width: 200px;
          height: 40px;
          cursor: pointer;
          border-radius: 5px;
          -moz-border-radius: 5px;
          -webkit-border-radius: 5px;
          background: #2254f4;
          color: #fff;
          font-size: 14px;
          text-align: center;
          line-height: 40px;
          background: linear-gradient(270deg,#2254f4,#406dff);
          box-shadow: 0 12px 30px 0 rgba(34,84,244,.2);
        }
      }
    }

    &:deep(.w-e-toolbar) {
      z-index: 2 !important;
    }
    &:deep(.w-e-text-container) {
      z-index: 1 !important;
    }
  }
</style>